<template>
  <div class="internal-invoice-listing">
    <v-layout class="my-2">
      <v-flex md3>
        <v-menu
          v-if="false"
          v-model="datePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              filled
              flat
              placeholder="Dates Range"
              hide-details
              solo
              :disabled="proformaLoading"
              :loading="proformaLoading"
              v-on:click:clear="clearFilter('dates')"
              clearable
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              :value="formattedDate"
              class="mr-2"
              color="cyan"
            ></v-text-field>
          </template>
          <v-date-picker
            color="cyan"
            range
            :disabled="proformaLoading"
            :loading="proformaLoading"
            v-on:change="getProforms"
            v-model="dates"
          ></v-date-picker>
        </v-menu>
        <DateRangePicker
          placeholder="Data Range"
          v-model="dates"
          v-on:update:model-value="getProforms()"
          v-on:click:clear="clearFilter('dates')"
          class="mr-2"
        ></DateRangePicker>
      </v-flex>
      <v-flex md3>
        <div class="d-flex">
          <v-text-field
            v-model.trim="search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            label="Search"
            hide-details
            solo
            :loading="proformaLoading"
            :disabled="proformaLoading"
            color="cyan"
            @keydown.enter="getProforms()"
            @keydown.tab="getProforms()"
          ></v-text-field>
          <v-btn
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            tile
            depressed
            :disabled="proformaLoading"
            v-on:click="getProforms()"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <v-btn
            class="mx-2 custom-bold-button white--text"
            depressed
            :disabled="proformaLoading"
            v-on:click="getProforms()"
            color="cyan white--text"
            ><v-icon>mdi-reload</v-icon></v-btn
          >
        </div>
      </v-flex>
      <v-flex md1> </v-flex>
    </v-layout>
    <ListingTable :columnCount="6" :rowData="rowData">
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th">Proforma #</th>
            <th class="simple-table-th">Type</th>
            <th class="simple-table-th">PDF</th>
            <th class="simple-table-th">Basic Detail</th>
            <th class="simple-table-th">Dates</th>
            <th class="simple-table-th">Discount / Text</th>
            <th class="simple-table-th">Amount</th>
            <th class="simple-table-th">Status</th>
            <th class="simple-table-th">Invoice</th>
            <th class="simple-table-th">Created Time</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="rowData.length">
            <tr
              v-for="(row, index) in rowData"
              :key="index"
              class="alternate-listing-row"
              v-on:click="routeToDetailLink(row)"
            >
              <td class="simple-table-td">
                <Barcode :barcode="row.barcode"></Barcode>
              </td>
              <td class="simple-table-td">
                <template v-if="row?.proforma_type">
                  <div class="my-0">
                    <v-chip
                      small
                      class="text-white p-3 cursor-pointer mt-1 text-capitalize"
                      :color="row?.proforma_type == 'initial' ? 'red' : 'green'"
                      label
                      outlined
                    >
                      {{ row?.proforma_type }}
                    </v-chip>
                  </div>
                </template>
                <template v-else> --</template>
              </td>
              <td class="simple-table-td">
                <v-icon
                  color="red"
                  v-on:click.stop.prevent="downloadFile(row.pdf_url)"
                  :disabled="!row.pdf_url"
                  >mdi-file-pdf-box</v-icon
                >
              </td>
              <td class="simple-table-td">
                <p class="m-0 custom-nowrap-ellipsis">
                  <b>Title : </b>{{ row.job_title }}
                </p>
                <p class="m-0 custom-nowrap-ellipsis">
                  <b>Reference: </b>
                  <template v-if="row.reference">{{ row.reference }}</template>
                  <template v-else
                    ><em class="text--secondary">No Reference</em></template
                  >
                </p>
              </td>
              <td class="simple-table-td">
                <div class="invoice-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <template v-if="row.date">{{
                      formatDate(row.date)
                    }}</template>
                    <template v-else
                      ><em class="text--secondary"
                        >No proforma Date</em
                      ></template
                    >
                  </p>
                </div>
              </td>

              <td class="simple-table-td">
                <div class="invoice-listing-amount">
                  <template v-if="row.proforma_type != 'initial'">
                    <p class="m-0 custom-nowrap-ellipsis">
                      <b>Discount </b>
                      <label class="m-0">
                        <span v-if="row.discount_value_type == 1"
                          >({{ row.discount_value }}) :
                          {{
                            $accountingJS.formatMoney(row.discount_amount)
                          }}</span
                        >
                        <span v-if="row.discount_value_type == 2"
                          >({{ row.discount_value }}%) :
                          {{
                            $accountingJS.formatMoney(row.discount_amount)
                          }}</span
                        >
                      </label>
                    </p>
                    <p class="m-0 custom-nowrap-ellipsis">
                      <b>Lump Sum Discount </b>
                      <label class="m-0">
                        <span v-if="row.lump_discount_type == 1"
                          >({{ row.lump_discount_value }}) :
                          {{
                            $accountingJS.formatMoney(row.lump_sum_discount)
                          }}</span
                        >
                        <span v-if="row.lump_discount_type == 2"
                          >({{ row.lump_discount_value }}%) :
                          {{
                            $accountingJS.formatMoney(row.lump_sum_discount)
                          }}</span
                        >
                      </label>
                    </p>
                  </template>
                  <template v-else>
                    <p class="m-0 custom-nowrap-ellipsis">
                      <label class="m-0">
                        <v-chip label small color="red" text-color="white"
                          >Auto Deduct</v-chip
                        >
                      </label>
                    </p>
                  </template>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>GST {{ row.tax_value }} % : </b>
                    <label class="m-0">{{
                      $accountingJS.formatMoney(row.tax_amount)
                    }}</label>
                  </p>
                </div>
              </td>
              <td class="simple-table-td">
                <div class="invoice-listing-amount">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Sub Total: </b>
                    <label class="m-0">{{
                      $accountingJS.formatMoney(row.sub_total)
                    }}</label>
                  </p>
                  <!-- <p
                    class="m-0 custom-nowrap-ellipsis"
                    v-if="row?.proforma_type != 'initial'"
                  >
                    <b>Initial Payment: </b>
                    <label class="m-0">{{
                      $accountingJS.formatMoney(row.initial_payment)
                    }}</label>
                  </p> -->
                  <!--   <p class="m-0 custom-nowrap-ellipsis">
                    <b>GST {{ row.tax_value }} % : </b>
                    <label class="m-0">{{
                      $accountingJS.formatMoney(row.tax_amount)
                    }}</label>
                  </p> -->
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Total &nbsp; &nbsp; &nbsp; : </b>
                    <label class="m-0">{{
                      $accountingJS.formatMoney(row.total)
                    }}</label>
                  </p>
                </div>
              </td>
              <td class="simple-table-td">
                <CustomStatus
                  v-if="false"
                  small
                  :status="row.status"
                  endpoint="invoice/status"
                ></CustomStatus>
                <v-menu
                  :disabled="row.status == 2 ? true : false"
                  transition="slide-y-transition"
                  bottom
                  content-class="custom-menu-list"
                  offset-y
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      label
                      :color="getStatusColor(row.status)"
                      text-color="white"
                      class="ml-2 text-uppercase font-weight-600"
                      small
                    >
                      {{ getStatus(row.status) }}
                      <v-icon v-if="row.status != 2" right
                        >mdi-chevron-down</v-icon
                      >
                    </v-chip>
                  </template>
                  <v-list>
                    <template v-for="(status, index) in statusList">
                      <v-list-item
                        v-if="status.text"
                        v-on:click.stop.prevent="
                          updateStatus(status.status, row)
                        "
                        link
                        :key="index"
                        style="padding: 0px"
                      >
                        <v-list-item-title class="font-weight-500 font-size-14">
                          <div class="d-flex">
                            <div
                              :style="{
                                backgroundColor: status.color,
                                height: '15px',
                                width: '15px',
                                borderRadius: '50%',
                              }"
                              class="ml-2"
                            ></div>

                            <v-chip
                              label
                              :color="status.color"
                              text-color="white"
                              class="ml-2 text-uppercase font-weight-600"
                              small
                            >
                              {{ status.text }}
                            </v-chip>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </td>
              <td class="simple-table-td position-relative">
                <template v-if="row.status == 2">
                  <template v-if="row && row.invoice && row?.status == 2">
                    <div class="d-flex">
                      <p
                        class="m-0 custom-nowrap-ellipsis"
                        v-on:click.stop.prevent="
                          invoiceViewDialog(row.invoice.id)
                        "
                      >
                        <b>Date: </b>
                        <template v-if="row.invoice?.invoice_date">
                          {{ formatDate(row.invoice.invoice_date) }}
                        </template>
                      </p>
                    </div>
                    <div
                      class="inv-btn-position"
                      v-if="row.invoice.payment_status != 'complate'"
                    >
                      <v-icon
                        v-on:click.stop.prevent="invoiceUpdateDialog(row)"
                        color="green"
                        size="25"
                        >mdi-pencil</v-icon
                      >
                    </div>
                    <p
                      class="m-0 custom-nowrap-ellipsis"
                      v-if="row.invoice?.invoice_amount"
                      v-on:click.stop.prevent="
                        invoiceViewDialog(row.invoice.id)
                      "
                    >
                      <b>Invoice: </b>
                      <template v-if="row.invoice?.invoice_amount">
                        {{ formatMoney(row.invoice.invoice_amount) }}
                      </template>
                    </p>
                    <p
                      class="m-0 custom-nowrap-ellipsis"
                      v-if="row.invoice?.collected_amount"
                      v-on:click.stop.prevent="
                        invoiceViewDialog(row.invoice.id)
                      "
                    >
                      <b>Collected: </b>
                      <template v-if="row.invoice?.collected_amount">
                        {{ formatMoney(row.invoice.collected_amount) }}
                      </template>
                    </p>
                    <p
                      class="m-0 custom-nowrap-ellipsis"
                      v-if="row?.invoice.payment_status"
                      v-on:click.stop.prevent="
                        invoiceViewDialog(row.invoice.id)
                      "
                    >
                      <b>Payment Status: </b>
                      <template v-if="row?.invoice.payment_status">
                        <v-chip
                          label
                          small
                          :color="
                            row.invoice.payment_status == 'complate'
                              ? 'green'
                              : 'red'
                          "
                          text-color=""
                          outlined
                        >
                          <template
                            v-if="row.invoice.payment_status == 'complate'"
                          >
                            Complete
                          </template>
                          <template v-else> Pending </template>
                        </v-chip>
                      </template>
                    </p>
                  </template>
                  <template v-if="row?.status == 2 && !row?.invoice">
                    <p class="m-0 custom-nowrap-ellipsis">
                      <v-tooltip
                        top
                        content-class="custom-top-tooltip"
                        z-index="99"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            style="height: 22px !important"
                            active-class="line-item-type-active"
                            color="cyan"
                            class="custom-bold-button text-white"
                            v-bind="attrs"
                            v-on="on"
                            v-on:click.stop.prevent="invoiceDialog(row)"
                          >
                            + Invoice
                          </v-btn>
                        </template>
                        <span>Create Invoice</span>
                      </v-tooltip>
                    </p>
                  </template>
                </template>
                <div v-else class="ml-6 font-weight-700 font-size-19">--</div>
              </td>
              <td class="simple-table-td">
                <TableActivity v-if="!lodash.isEmpty(row.added_by)" :data="row">
                  <template v-slot:display_name>
                    {{ row.added_by.display_name }}
                  </template>
                  <template v-slot:date_time>
                    {{ row.created_at }}
                  </template>
                  <template v-slot:format_date_time>
                    {{ formatDateTime(row.added_at) }}
                  </template>
                </TableActivity>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="10">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no proforma at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
        <Invoice
          v-if="invoice_dialog"
          :reassign-schedule-dialog="invoice_dialog"
          v-on:close="invoice_dialog = false"
          :order-id="typeId"
          :updated-id="invoice_id"
          :proforma-data="proformaParent"
          v-on:success="getProforms()"
        ></Invoice>
        <InvoiceView
          :invoice-view-dialog="invoice_view_dialog"
          v-on:close="invoice_view_dialog = false"
          :updated-id="invoice_id"
        ></InvoiceView>
      </template>
    </ListingTable>
    <ListingFooter
      :dataLoading="proformaLoading"
      :showingFrom="showingFrom"
      :showingTo="showingTo"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :totalPages="totalPages"
    ></ListingFooter>
  </div>
</template>
  
  <script>
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import { QUERY, PATCH } from "@/core/services/store/request.module";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import DateRangePicker from "@/view/components/DateRangePicker";
import Invoice from "@/view/pages/partials/Proforma-Invoice";
import InvoiceView from "@/view/pages/partials/Proforma-Invoice-View";

export default {
  mixins: [CommonMixin],
  data() {
    return {
      proformaLoading: false,
      dates: [],
      rowData: [],
      currentPage: 1,
      status: "all",
      search: null,
      totalPages: 0,
      totalRows: 0,
      rowsOffset: 0,
      invoice_dialog: false,
      invoice_view_dialog: false,
      invoice_id: 0,
      proformaParent: {},
      statusList: [
        {
          text: "Draft",
          color: "cyan",
          status: 1,
        },
        {
          text: "Send",
          color: "orange",
          status: 2,
        },
        /*  {
          text: "Complete",
          color: "green",
          status: 3,
        }, */
      ],
    };
  },
  props: {
    typeId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    typeId() {
      this.$nextTick(() => {
        this.getProforms();
      });
    },
  },
  methods: {
    invoiceDialog(parent) {
      this.invoice_id = 0;
      this.proformaParent = parent;
      this.invoice_dialog = true;
    },
    invoiceUpdateDialog(parent) {
      this.proformaParent = parent;
      this.invoice_id = parent?.invoice?.id;
      this.invoice_dialog = true;
    },
    invoiceViewDialog(invoice) {
      this.invoice_id = invoice;
      this.invoice_view_dialog = true;
    },
    downloadFile(url) {
      if (!url) {
        return false;
      }
      window.open(url, "_blank");
    },
    routeToDetailLink(row) {
      this.$nextTick(() => {
        this.$router.push(
          this.getDefaultRoute("proforma.detail", {
            params: {
              id: row.id,
            },
          })
        );
      });
    },

    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getProforms();
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return "Draft";
        case 2:
          return "Send";
        case 3:
          return "Completed";
      }
    },
    getStatusColor(status) {
      switch (status) {
        case 1:
          return "cyan";
        case 2:
          return "orange";
        case 3:
          return "green";
      }
    },
    updateStatus(proforma_status, row) {
      if (this.proformaLoading) {
        return false;
      }
      this.proformaLoading = true;
      this.$store
        .dispatch(PATCH, {
          url: "proforma/" + row.id + "/status",
          data: { status: proforma_status },
        })
        .then(() => {
          this.getProforms();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.proformaLoading = false;
        });
    },
    getProforms() {
      const data = {
        current_page: this.currentPage,
        entity: 0,
        search: this.search,
        status: this.status,
        order_id: this.typeId,
        dates: this.dates,
      };

      this.proformaLoading = true;

      this.$store
        .dispatch(QUERY, { url: "performa", data: data })
        .then(({ data }) => {
          this.totalPages = Math.ceil(data.total_rows / data.per_page);
          this.currentPage = data.current_page;
          this.rowData = data.rows;
          this.totalRows = data.total_rows;
          this.rowsOffset = data.offset;
          /*    this.statusList = data.statusList; */
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.proformaLoading = false;
        });
    },
    routeToInvoice(row) {
      this.$nextTick(() => {
        this.$router.push(
          this.getDefaultRoute("invoice.detail", {
            params: {
              id: row.id,
            },
          })
        );
      });
    },
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.rowData) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.rowData ? this.rowData.length : 0);
    },
  },
  mounted() {
    this.getProforms();
  },
  components: {
    Barcode,
    TableActivity,
    CustomStatus,
    ListingFooter,
    ListingTable,
    DateRangePicker,
    Invoice,
    InvoiceView,
  },
};
</script>
  