<template>
  <Dialog :common-dialog="invoiceViewDialog" :dialog-width="dialogWidth">
    <template v-slot:title>
      Proforma Invoice
      <v-chip
        v-if="invoiceData && invoiceData.barcode"
        label
        color="chip-custom-blue"
        class="text-white p-3 ml-2"
        outlined
        small
        >{{ invoiceData.barcode }}
      </v-chip>

      <!--   <v-chip
        label
        class="ml-2 custom-status font-weight-600 custom-grey-border text-uppercase"
        color="cyan"
        v-if="invoiceData && invoiceData.barcode"
      >
        {{ invoiceData.barcode }}
      </v-chip> -->
    </template>
    <template v-slot:body>
      <v-container class="px-10" fluid>
        <v-row>
          <v-col md="4">
            <div class="d-flex">
              <label
                class="font-weight-600"
                for="one-off-start-time"
                style="font-size: 15px"
                >Invoice Date :
              </label>

              <v-chip
                small
                label
                class="ml-5 custom-status font-weight-600 custom-grey-border text-uppercase custom-fonts"
                text-color=""
                color="blue"
                v-if="invoiceData && invoiceData.invoice_date"
                outlined
              >
                {{ formatDate(invoiceData.invoice_date) }}
              </v-chip>
              <em v-else class="text-muted ml-5"> no invoice date</em>
            </div>
          </v-col>
          <v-col md="4">
            <div class="d-flex">
              <label
                class="font-weight-600"
                for="one-off-start-time"
                style="font-size: 15px"
                >Invoice Amount :
              </label>
              <v-chip
                label
                outlined
                small
                text-color=""
                v-if="invoiceData && invoiceData.invoice_amount"
                class="ml-5 custom-fonts"
                color="green"
              >
                {{ formatMoney(invoiceData.invoice_amount) }}
              </v-chip>
              <v-chip
                label
                outlined
                v-else
                small
                text-color=""
                class="ml-5 custom-fonts"
                color="red"
              >
                0.00
              </v-chip>
            </div>
          </v-col>
          <v-col md="4">
            <div class="d-flex">
              <label
                class="font-weight-600 text-nowarp"
                for="one-off-start-time"
                style="font-size: 15px"
                >Collected Amount :
              </label>
              <v-chip
                label
                outlined
                v-if="invoiceData && invoiceData.collected_amount"
                small
                text-color=""
                class="ml-5 custom-fonts"
                color="red"
              >
                {{ formatMoney(invoiceData.collected_amount) }}
              </v-chip>
              <v-chip
                label
                outlined
                v-else
                small
                text-color=""
                class="ml-5 custom-fonts"
                color="red"
              >
                0.00
              </v-chip>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <label
              class="font-weight-600"
              for="one-off-start-time"
              style="font-size: 15px"
              >Status&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:</label
            >

            <template v-if="invoiceData && invoiceData.payment_status">
              <v-chip
                label
                :color="
                  invoiceData.payment_status == 'complate' ? 'green' : 'red'
                "
                text-color=""
                outlined
                small
                class="ml-5 custom-fonts"
              >
                <template v-if="invoiceData.payment_status == 'complate'">
                  Complete
                </template>
                <template v-else> Pending </template>
              </v-chip>
            </template>
          </v-col>
          <v-col md="8">
            <div class="d-flex">
              <label
                class="font-weight-600 text-nowrap"
                for="one-off-start-time"
                style="font-size: 15px"
                >Remark &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; :
              </label>
              <div class="ml-5">
                <span class="" v-if="invoiceData && invoiceData.remark"
                  >{{ invoiceData.remark }}
                </span>
                <em v-else class="text-muted ml-5"> no remark</em>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="12">
            <div class="form-grp-title">Attachments</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <div class="overflow-y" style="max-height: calc(100vh - 345px)">
              <table width="100%" class="detail-table table-head-sticky">
                <thead>
                  <tr class="custom-border">
                    <th class="simple-table-th border-right-new"></th>
                    <th class="simple-table-th border-right-new">Name</th>
                    <th class="simple-table-th border-right-new">Added At</th>
                    <th class="simple-table-th"></th>
                  </tr>
                </thead>

                <tbody
                  v-if="
                    invoiceData &&
                    invoiceData.attachment &&
                    invoiceData.attachment.length
                  "
                >
                  <tr
                    v-for="(row, index) in invoiceData.attachment"
                    :key="index"
                    class="custom-border"
                  >
                    <td class="p-2 border-top-light-grey border-right-new">
                      <template v-if="row.extension && isImage(row.extension)">
                        <ImageTemplate
                          :src="row.file.url"
                          style="max-width: 50px; width: 50px"
                        ></ImageTemplate>
                      </template>
                      <template v-else>
                        <inline-svg
                          style="max-width: 50px; width: 50px"
                          :src="$assetURL(`media/mime/${row.extension}.svg`)"
                        />
                      </template>
                    </td>
                    <td class="p-2 border-top-light-grey border-right-new">
                      <p
                        class="m-0 blue--text font-level-1 cursor-pointer bold-600"
                        v-on:click="doAction(row, 'download')"
                      >
                        {{ row.name }}
                      </p>
                    </td>

                    <td
                      class="p-2 border-top-light-grey font-level-1 border-right-new"
                    >
                      <div class="d-flex justify-space-between">
                        <v-chip
                          color="cyan"
                          label
                          class="text-white"
                          outlined
                          style="height: 25px"
                        >
                          {{ formatDate(row.added_at) }}
                        </v-chip>
                      </div>
                    </td>
                    <td class="p-2 border-top-light-grey font-level-1">
                      <div class="d-flex justify-space-between">
                        <template v-if="row && row.added_by && false">
                          <Barcode
                            :barcode="row.added_by.display_name"
                          ></Barcode>
                        </template>
                        <!--  <div md1>{{ getFileSize(row.size) }} KB</div> -->
                      </div>
                    </td>
                    <td class="p-2 border-top-light-grey font-level-1">
                      <v-btn
                        v-on:click="doAction(row, 'download')"
                        icon
                        depressed
                        color="blue darken-4"
                        class="mr-2"
                        ><v-icon medium>mdi-download</v-icon></v-btn
                      >
                      <!--  <v-btn
                                v-on:click="doAction(row, 'delete')"
                                icon
                                depressed
                                color="red lighten-1"
                                ><v-icon medium>mdi-delete</v-icon></v-btn
                              > -->
                    </td>
                  </tr>
                </tbody>
                <tfoot v-else>
                  <tr>
                    <td colspan="4">
                      <p class="m-0 row-not-found text-center">
                        <img
                          :src="$assetURL('media/error/empty.png')"
                          class="row-not-found-image"
                        />
                        Uhh... There are no file at the moment.
                      </p>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:action>
      <v-btn
        v-on:click="close_dialog()"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>
  
  <script>
/*   import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib"; */
import { GET } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Dialog from "@/view/pages/partials/Dialog";
import moment from "moment-timezone";
import Barcode from "@/view/pages/partials/Barcode.vue";
import ImageTemplate from "@/view/pages/Image";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  props: {
    invoiceViewDialog: {
      type: Boolean,
      default: false,
    },

    updatedId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    updatedId: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param && param > 0) {
          this.getInvoice(param);
        }
      },
    },
    invoiceViewDialog: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param && this.updatedId > 0) {
          this.getInvoice(this.updatedId);
        }
      },
    },
  },
  data() {
    return {
      formLoading: false,
      formValid: true,

      proforma_invoice_id: 0,
      invoiceData: null,
    };
  },
  methods: {
    isImage(ext) {
      if (find(this.extensionArray, (row) => row == ext.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    doAction(row, param) {
      if (param == "download") {
        window.open(row.file.url, "_blank");
      }
    },
    getInvoice(id) {
      if (id > 0) {
        this.proforma_invoice_id = id;
        const _this = this;
        _this.$store
          .dispatch(GET, { url: `proforma-invoice/${id}` })
          .then(({ data }) => {
            _this.invoiceData = data;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      }
    },
    close_dialog() {
      this.$emit("close", true);
    },
  },
  components: {
    Dialog,
    Barcode,
    ImageTemplate,
  },
  mounted() {},
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
    todayDate() {
      return moment().format("YYYY-MM-DD");
    },
  },
};
</script>
  